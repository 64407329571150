import { mapGetters } from 'vuex';

export default {
	name: 'PublicContractBuyer',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			lang: 'lang/id'
		}),
	},
	data() {
		return {
			title: this.lang === 4 ? 'Публічний договір про надання посередницьких послуг' : 'Публичный договор о предоставлении посреднических услуг',
			description: this.lang === 4 ? 'Фізична особа (Надалі «Сторона-1») з одного боку, і Товариство з обмеженою відповідальністю «Інтернет Реклама РІА» з іншого боку, (в подальшому іменується "Сторона-2»)' : 'Физическое лицо (В дальнейшем «Сторона-1») с одной стороны, и Общество с ограниченной ответственностью «Интернет Реклама РИА» с другой стороны, (в дальнейшем именуется "Сторона-2»)',
		};
	},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{name: 'description', content: this.description},
				{property: 'og:site_name', content: 'AUTO.RIA'},
				{property: 'og:description', content: this.description},
				{property: 'og:image', content: 'https://auto.ria.com/share_16.png'},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/public-contract/buyer/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/public-contract/buyer/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/public-contract/buyer/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/public-contract/buyer/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/public-contract/buyer/'},
				{rel: 'canonical', href: 'https://auto.ria.com/public-contract/buyer/'}
			]
		};
	}
};
